import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Store } from "@ngxs/store";
import * as deepClone from "lodash.clonedeep";
import {
    CountriesResponseModel,
    Country,
} from "src/app/services/response-models/countries.response.model";
import { SetCountries } from "src/store/app/actions/set-countries.action";
import { AppState } from "src/store/app/app.state";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class CountriesService {
    private readonly countriesUrl = `${environment.apiBaseUrl}countries`;

    constructor(
        private http: HttpClient,
        private store: Store
    ) {}

    public async getCountries(): Promise<Country[]> {
        const countries: Country[] = this.store.selectSnapshot(AppState.getCountries);

        if (countries?.length) {
            return deepClone(countries.slice());
        }

        const { data } = await lastValueFrom(
            this.http.get<CountriesResponseModel>(this.countriesUrl)
        );

        this.store.dispatch(new SetCountries(data));

        return data;
    }
}
