import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "src/app/login/login/login.component";
import { ErrorPageComponent } from "src/app/error-page/error-page.component";

const routes: Routes = [
    {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
    },
    {
        path: "login",
        component: LoginComponent,
    },
    {
        path: "employee",
        loadChildren: () => import("./employee/employee.module").then((m) => m.EmployeeModule),
    },
    {
        path: "error",
        component: ErrorPageComponent,
    },
    {
        path: "**",
        redirectTo: "error",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
