import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import {
    ClearBasicProducts,
    LoadBasicPagination,
    LoadBasicProducts,
} from "src/store/products/actions/set-basic-products.action";
import {
    ClearVendorProducts,
    LoadVendorProducts,
    LoadVendorProductsPagination,
} from "src/store/products/actions/set-vendor-products.action";
import {
    ClearExpenseLinesProducts,
    LoadExpenseLinesPagination,
    LoadExpenseLinesProducts,
} from "src/store/products/actions/set-expense-products.action";
import { AppState } from "src/store/app/app.state";
import { lastValueFrom, Observable } from "rxjs";
import { User } from "src/app/services/response-models/user.response.model";
import { GeneralSuccessResp } from "src/app/services/response-models/companies.response.model";
import { ToastService } from "src/app/services/toast.service";
import {
    CreatedProduct,
    LastUsedProductsResponseModel,
    Product,
} from "src/app/services/response-models/product.response.model";
import { LoadAllProductsOrdered } from "src/store/products/actions/set-all-products.action";
import { ProductsState } from "src/store/products/products.state";
import { sentryCaptureException } from "src/app/utility/errors";
import { ApactaApiSdkService } from "src/app/services/apacta-api-sdk.service";
import { PaginationDetails } from "@apacta/sdk";

@Injectable({
    providedIn: "root",
})
export class ProductService {
    @Select(AppState.getUser) user$: Observable<User>;

    private readonly productsUrl: string = `${environment.apiBaseUrl}products`;
    private readonly formsProductsUrl: string = `${environment.apiBaseUrl}forms_products`;
    private readonly formsUrl = `${environment.apiBaseUrl}forms`;

    constructor(
        private store: Store,
        private http: HttpClient,
        private toastService: ToastService,
        private apactaSdk: ApactaApiSdkService
    ) {}

    public async getProducts(searchQuery = ""): Promise<void> {
        await this.getBasicProducts(1, searchQuery);
        this.getExpenseLinesProducts(1, searchQuery);
        this.getVendorProducts({ searchQuery: searchQuery });
    }

    public async getLastUsedProducts(): Promise<void> {
        this.user$
            .subscribe(async (resp) => {
                const headers = {
                    params: {
                        direction: "desc",
                        sort: "FormsProducts.created",
                        uniqueProducts: "true",
                    },
                };

                const { data, pagination } = await lastValueFrom(
                    this.http.get<LastUsedProductsResponseModel>(this.formsProductsUrl, headers)
                ).catch((err) => this.handleError(err));

                const products = data.map((x) => x.product);

                const blankPagination = {
                    count: 1,
                    pageCount: 1,
                    currentPage: 1,
                    hasNextPage: false,
                    hasPrevPage: false,
                } as PaginationDetails;

                // Clear all products
                this.store.dispatch(new ClearBasicProducts());
                this.store.dispatch(new ClearVendorProducts());
                this.store.dispatch(new ClearExpenseLinesProducts());

                this.store.dispatch(new LoadBasicProducts(products));
                this.store.dispatch(new LoadBasicPagination(pagination));
                this.store.dispatch(new LoadVendorProductsPagination(blankPagination));
                this.store.dispatch(new LoadExpenseLinesPagination(blankPagination));
            })
            .unsubscribe();
    }

    public async getBasicProducts(page = 1, searchQuery = ""): Promise<Product[]> {
        const headers = {
            page: page,
            q: searchQuery,
        };

        const apactaSdk = this.apactaSdk.configuration();

        const { data, pagination } = await apactaSdk
            .productsList(headers)
            .catch((err) => this.handleError(err));

        if (page === 1) {
            this.store.dispatch(new ClearBasicProducts());
        }

        this.store.dispatch(new LoadBasicProducts(data));
        this.store.dispatch(new LoadBasicPagination(pagination));
        this.store.dispatch(
            new LoadAllProductsOrdered({
                order: 0,
                products: this.store.selectSnapshot(ProductsState.getBasicProducts),
            })
        );

        return data;
    }

    public async getVendorProducts({ page = 1, searchQuery = "" }): Promise<any> {
        const apactaSdk = this.apactaSdk.configuration();

        const { data, pagination } = await apactaSdk
            .vendorProductsList({
                page: page,
                q: searchQuery,
            })
            .catch((err) => this.handleError(err));

        if (page === 1) {
            this.store.dispatch(new ClearVendorProducts());
        }

        this.store.dispatch(new LoadVendorProducts(data));
        this.store.dispatch(new LoadVendorProductsPagination(pagination));
        this.store.dispatch(
            new LoadAllProductsOrdered({
                order: 2,
                products: this.store.selectSnapshot(ProductsState.getVendorProducts),
            })
        );

        return data;
    }

    public async getExpenseLinesProducts(page = 1, searchQuery = ""): Promise<any> {
        const headers = {
            page: page,
            q: searchQuery,
        };

        const apactaSdk = this.apactaSdk.configuration();

        const { data, pagination } = await apactaSdk
            .expenseLinesList(headers)
            .catch((err) => this.handleError(err));

        if (page === 1) {
            this.store.dispatch(new ClearExpenseLinesProducts());
        }

        this.store.dispatch(new LoadExpenseLinesProducts(data));
        this.store.dispatch(new LoadExpenseLinesPagination(pagination));
        this.store.dispatch(
            new LoadAllProductsOrdered({
                order: 1,
                products: this.store.selectSnapshot(ProductsState.getExpenseLinesProducts),
            })
        );

        return data;
    }

    public async createProduct(body): Promise<CreatedProduct> {
        return await lastValueFrom(this.http.post<CreatedProduct>(this.productsUrl, body));
    }

    public async deleteFormProduct(
        form_id: string,
        form_product_id: string
    ): Promise<GeneralSuccessResp> {
        const url = `${this.formsUrl}/${form_id}/forms_products/${form_product_id}`;
        return await lastValueFrom(this.http.delete<GeneralSuccessResp>(url));
    }

    public async getProductsByFormId(form_id: string) {
        const url = `${this.formsUrl}/${form_id}/forms_products`;
        const { data } = await lastValueFrom(
            this.http.get<LastUsedProductsResponseModel>(url)
        ).catch((err) => this.handleError(err));
        return data;
    }

    private handleError(err) {
        if (err.message === "Timeout has occurred") {
            sentryCaptureException(err);
            this.toastService.showToastMessage("TOAST.BAD_CONNECTION");
            return { data: [] as any, pagination: null, success: false };
        }

        sentryCaptureException(err);
        this.toastService.showGeneralErrorMessage();
        return { data: [] as any, pagination: null, success: false };
    }
}
