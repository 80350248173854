import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Store } from "@ngxs/store";
import { SetCompanySettings } from "src/store/app/actions/set-settings.action";
import { SetUserCompany } from "src/store/app/actions/set-user-company.action";
import {
    ClearStockLocations,
    SetStockLocations,
    SetStockLocationsPagination,
} from "src/store/app/actions/set-stock-locations.action";
import { SetLanguages } from "src/store/app/actions/set-languages.action";
import { AuthService } from "src/app/services/auth.service";
import {
    Company,
    CompanyActivities,
    CompanySettings,
    CompanySettingsRoot,
    CompanySuccessResponse,
    GetStockLocationsResponseModel,
    LanguagesResponseModel,
} from "src/app/services/response-models/companies.response.model";
import { UtilityService } from "src/app/services/utility.service";
import { IncludeType } from "src/app/shared/enums/include-type.enum";
import { SetCompanyCurrency } from "src/store/companies/actions/companies.actions";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class CompaniesService {
    private readonly companyUrl = `${environment.apiBaseUrl}companies`;
    private readonly companyActivitiesUrl: string = `${environment.apiBaseUrl}activities`;
    private readonly companyLangsUrl = `${environment.apiBaseUrl}languages`;
    private readonly stockLocationsUrl = `${environment.apiBaseUrl}stock_locations`;

    constructor(
        private http: HttpClient,
        private store: Store,
        private authService: AuthService
    ) {}

    public async getCompanySettings(): Promise<CompanySettings> {
        const url = `${this.companyUrl}/settings`;

        const { company_settings } = await lastValueFrom(this.http.get<CompanySettingsRoot>(url));
        this.store.dispatch(new SetCompanySettings(company_settings));
        return company_settings;
    }

    public getCompanyActivities(): Promise<CompanyActivities> {
        return lastValueFrom(this.http.get<CompanyActivities>(this.companyActivitiesUrl));
    }

    public getLangs = async () => {
        const { data } = await lastValueFrom(
            this.http.get<LanguagesResponseModel>(this.companyLangsUrl)
        );

        this.store.dispatch(new SetLanguages(data));

        return data;
    };

    public async getStockLocations(page = 1, name = "") {
        const headers = {
            params: {
                page: page.toString(),
                name,
            },
        };

        const { data, pagination } = await lastValueFrom(
            this.http.get<GetStockLocationsResponseModel>(this.stockLocationsUrl, headers)
        );

        if (page === 1) {
            this.store.dispatch(new ClearStockLocations());
        }

        this.store.dispatch(new SetStockLocations(data));
        this.store.dispatch(new SetStockLocationsPagination(pagination));

        return data;
    }

    public async getCompanyById(
        companyId: string,
        includes?: string
    ): Promise<CompanySuccessResponse> {
        const defaultIncludes = UtilityService.getIncludes([
            IncludeType.Subscriptions,
            [IncludeType.Integrations, IncludeType.IntegrationSettings],
            [IncludeType.Integrations, IncludeType.IntegrationTypes],
            IncludeType.Cities,
            IncludeType.Files,
            IncludeType.Languages,
            [
                IncludeType.IntegrationSettings,
                IncludeType.Integrations,
                IncludeType.IntegrationTypes,
            ],
            IncludeType.FeatureGroups,
            [IncludeType.Countries, IncludeType.Currencies],
        ]);
        const headers = {
            params: {
                include: includes || defaultIncludes,
            },
        };

        const data = await lastValueFrom(
            this.http.get<CompanySuccessResponse>(`${this.companyUrl}/${companyId}`, headers)
        );

        let activeUsersCount = data.data?.subscriptions[0]?.seats;

        if (!activeUsersCount) {
            activeUsersCount = 0;
        }

        return data;
    }

    public async setUserCompany(): Promise<Company> {
        const authUser = await this.authService.getAuthUser();
        const companyResp = await this.getCompanyById(authUser.company_id);
        this.store.dispatch(new SetUserCompany(companyResp.data));

        if (companyResp.data?.country?.currency) {
            this.store.dispatch(new SetCompanyCurrency(companyResp.data.country.currency));
        }

        return companyResp.data;
    }
}
