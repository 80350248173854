import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
    ApiKey,
    LoginCredentials,
    OauthData,
} from "src/app/services/response-models/apikey.response.model";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ApiKeyService {
    private url: string = `${environment.apiBaseUrl}apiKey`;
    private oauthUrl: string = `${environment.apiBaseUrl}apiKey/oauth`;

    constructor(private http: HttpClient) {}

    public authorize(submittedData: LoginCredentials): Promise<ApiKey> {
        return lastValueFrom(this.http.post<ApiKey>(this.url, submittedData));
    }

    public oauth(oauthData: OauthData): Promise<ApiKey> {
        return lastValueFrom(this.http.post<ApiKey>(this.oauthUrl, oauthData));
    }
}
