import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { environment } from "src/environments/environment";
import {
    ClearCheckInMaterials,
    LoadCheckInMaterials,
    LoadCheckInMaterialsPaginaton,
} from "src/store/materials/actions/checkin-materials.action";
import {
    ClearCheckOutMaterials,
    LoadCheckOutMaterials,
    LoadCheckOutMaterialsPaginaton,
} from "src/store/materials/actions/checkout-materials.action";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { SelectedMaterialRental } from "src/app/employee/forms/form-field-types/materials/models/SelectedMaterialRental";
import { SelectedMaterial } from "src/app/employee/forms/form-field-types/materials/models/SelectedMaterials";
import {
    CreateRentalResponseModel,
    MaterialRental,
    MaterialsOverview,
    MaterialsResponseModel,
} from "src/app/services/response-models/materials.response.model";
import { firstValueFrom, lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class MaterialService {
    private readonly getMaterialsBaseUrl: string = `${environment.apiBaseUrl}materials`;

    constructor(
        private store: Store,
        private http: HttpClient,
        private translateService: TranslateService
    ) {}

    public getAllMaterials = async (projectId: string, page = 1) => {
        const headers = {
            params: {
                project_id: projectId,
                page: page.toString(),
                sort: "",
            },
        };

        const { data, pagination } = await lastValueFrom(
            this.http.get<MaterialsResponseModel>(this.getMaterialsBaseUrl, headers)
        );
        const materials: MaterialRental[] = [];

        data.forEach((material) => {
            material.material_rentals.forEach((rental) => {
                if (rental.to_date) {
                    rental.group = material.name;
                    materials.push(rental);
                }
            });
        });

        materials.sort((a, b) => moment(b.to_date).diff(moment(a.to_date)));

        return { materials, pagination } as MaterialsOverview;
    };

    public getCheckInMaterials = async (page = 1, query = ""): Promise<void> => {
        const headers = {
            params: {
                currently_rented: "false",
                q: query,
                page: page.toString(),
            },
        };

        const { data, pagination } = await lastValueFrom(
            this.http.get<MaterialsResponseModel>(this.getMaterialsBaseUrl, headers)
        );

        if (page === 1) {
            this.store.dispatch(new ClearCheckInMaterials());
        }

        this.store.dispatch(new LoadCheckInMaterials(data));
        this.store.dispatch(new LoadCheckInMaterialsPaginaton(pagination));
    };

    public createCheckInMaterial = async (
        material: SelectedMaterial,
        formId: string
    ): Promise<CreateRentalResponseModel> => {
        const url = `${this.getMaterialsBaseUrl}/${material.material.id}/rentals`;
        const rentalDate = `${material.date} ${material.time}`;
        const body = {
            form_id: formId,
            quantity: material.amount,
            from_date: rentalDate,
            project_id: material.project_id,
        };

        return await lastValueFrom(this.http.post<CreateRentalResponseModel>(url, body));
    };

    public searchCheckInMaterialsByBarcode = async (barcode: string) => {
        const headers = {
            params: {
                currently_rented: "false",
                barcode,
            },
        };

        const { data } = await lastValueFrom(
            this.http.get<MaterialsResponseModel>(this.getMaterialsBaseUrl, headers)
        );

        this.store.dispatch(new ClearCheckInMaterials());
        this.store.dispatch(new LoadCheckInMaterials(data));
    };

    public getCheckOutMaterials = async (
        projectId: string,
        query = "",
        page = 1
    ): Promise<void> => {
        let rentals: Array<MaterialRental> = [];

        const headers = {
            params: {
                currently_rented: "true",
                project_id: projectId,
                q: query,
                page: page.toString(),
            },
        };

        const { data, pagination } = await lastValueFrom(
            this.http.get<MaterialsResponseModel>(this.getMaterialsBaseUrl, headers)
        );

        for (const material of data) {
            for (const rental of material.material_rentals) {
                rental.group = material.name;
                rental.display_name = await this.setDisplayDate(rental.from_date, rental.quantity);
                rental.rental_type = material.billing_cycle;
            }

            rentals = rentals.concat(material.material_rentals);
        }

        if (page === 1) {
            this.store.dispatch(new ClearCheckOutMaterials());
        }

        this.store.dispatch(new LoadCheckOutMaterials(rentals));
        this.store.dispatch(new LoadCheckOutMaterialsPaginaton(pagination));
    };

    public searchCheckOutMaterialsByBarcode = async (projectId: string, barcode: string) => {
        let rentals: Array<MaterialRental> = [];

        const headers = {
            params: {
                currently_rented: "true",
                project_id: projectId,
                barcode,
            },
        };

        const { data } = await lastValueFrom(
            this.http.get<MaterialsResponseModel>(this.getMaterialsBaseUrl, headers)
        );

        for (const material of data) {
            for (const rental of material.material_rentals) {
                rental.group = material.name;
                rental.display_name = await this.setDisplayDate(rental.from_date, rental.quantity);
                rental.rental_type = material.billing_cycle;
            }

            rentals = rentals.concat(material.material_rentals);
        }

        this.store.dispatch(new ClearCheckOutMaterials());
        this.store.dispatch(new LoadCheckOutMaterials(rentals));
    };

    public checkOutMaterialRental = async (material: SelectedMaterialRental, formId: string) => {
        const url = `${this.getMaterialsBaseUrl}/${material.material.material_id}/rentals/checkout`;
        const body = {
            form_id: formId,
            to_date: material.date,
            material_rental_id: material.material.id,
        };

        return await lastValueFrom(this.http.post<CreateRentalResponseModel>(url, body));
    };

    private setDisplayDate = async (utcDate: string, quantity: number): Promise<string> => {
        const date = moment(utcDate);

        const translations = await firstValueFrom(this.translateService.get("SHARED"));

        return `${quantity} ${translations.FROM} ${date.format("hh:mm DD MMM YYYY")}`;
    };
}
