import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { CalendarModule } from "ion2-calendar";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { IonicSelectableModule } from "ionic-selectable";
import { LoadingComponent } from "src/app/shared/loading/loading.component";
import { FileViewComponent } from "src/app/shared/file-view/file-view.component";
import { SecondsToHoursPipe } from "src/app/shared/pipes/seconds-to-hours.pipe";
import { BackArrowComponent } from "src/app/shared/back-arrow/back-arrow.component";
import { CalendarComponent } from "src/app/shared/calendar/calendar.component";
import { ParseCalendarDatePipe } from "src/app/shared/pipes/parse-calendar-date.pipe";
import { PickerComponent } from "src/app/shared/picker/picker.component";
import { PickerSymbolOptionsComponent } from "src/app/shared/picker/components/picker-symbol-options/picker-symbol-options.component";
import { ListAddressesComponent } from "src/app/shared/list-addresses/list-addresses.component";
import { HeaderComponent } from "src/app/shared/header/header.component";
import { SetProjectFilterDatePipe } from "src/app/employee/projects/pipes/set-project-filter-date.pipe";
import { EventTimePipe } from "src/app/employee/projects/pipes/event-time.pipe";
import { ProjectDescriptionPipe } from "src/app/employee/projects/pipes/project-description.pipe";
import { DateTimePickerComponent } from "src/app/shared/date-time-picker/date-time-picker.component";
import { ParseCardDatePipe } from "src/app/shared/pipes/parse-card-date.pipe";
import { LinkifyPipe } from "src/app/shared/pipes/linkify.pipe";
import { ApactaButtonComponent } from "src/app/shared/apacta-button/apacta-button.component";
import { PreventDoubleClickDirective } from "src/app/shared/prevent-double-click/prevent-double-click.directive";
import { MarkdownPipe } from "src/app/shared/pipes/markdown.pipe";
import { MarkdownRemoverPipe } from "src/app/shared/pipes/markdown-remover.pipe";
import { ShowPasswordDirective } from "src/app/shared/show-password/show-password.directive";
import { PhoneNumberPipe } from "src/app/shared/pipes/phone-number.pipe";

@NgModule({
    declarations: [
        LoadingComponent,
        ApactaButtonComponent,
        FileViewComponent,
        SecondsToHoursPipe,
        BackArrowComponent,
        CalendarComponent,
        ParseCalendarDatePipe,
        PickerComponent,
        PickerSymbolOptionsComponent,
        ListAddressesComponent,
        HeaderComponent,
        SetProjectFilterDatePipe,
        EventTimePipe,
        ProjectDescriptionPipe,
        DateTimePickerComponent,
        ParseCardDatePipe,
        LinkifyPipe,
        PhoneNumberPipe,
        MarkdownPipe,
        MarkdownRemoverPipe,
        PreventDoubleClickDirective,
        ShowPasswordDirective,
    ],
    imports: [
        CommonModule,
        IonicModule,
        CalendarModule,
        FormsModule,
        TranslateModule,
        IonicSelectableModule,
    ],
    exports: [
        ApactaButtonComponent,
        LoadingComponent,
        SecondsToHoursPipe,
        BackArrowComponent,
        CalendarModule,
        CalendarComponent,
        FormsModule,
        PickerComponent,
        PickerSymbolOptionsComponent,
        ListAddressesComponent,
        HeaderComponent,
        SetProjectFilterDatePipe,
        EventTimePipe,
        ProjectDescriptionPipe,
        DateTimePickerComponent,
        ParseCardDatePipe,
        LinkifyPipe,
        PhoneNumberPipe,
        MarkdownPipe,
        MarkdownRemoverPipe,
        PreventDoubleClickDirective,
        ShowPasswordDirective,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
