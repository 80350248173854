import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Store } from "@ngxs/store";
import { LoadCompanySettings } from "src/store/app/actions/set-company-settings.action";
import {
    CompanySetting,
    GetCompanySettingResponseModel,
} from "src/app/services/response-models/company-settings-response.model";
import { CompaniesService } from "src/app/services/companies.service";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class CompanySettingsService {
    private readonly companySettingsUrl: string = `${environment.apiBaseUrl}company_settings`;

    constructor(
        private http: HttpClient,
        private store: Store,
        protected companiesService: CompaniesService
    ) {}

    public async getCompanySettings(): Promise<CompanySetting[]> {
        const { data } = await lastValueFrom(
            this.http.get<GetCompanySettingResponseModel>(this.companySettingsUrl)
        );

        this.store.dispatch(new LoadCompanySettings(data));

        return data;
    }
}
