import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngxs/store";
import { environment } from "src/environments/environment";
import { SetCurrencies } from "src/store/app/actions/set-currencies.action";
import { map } from "rxjs/operators";
import {
    CurrenciesResponse,
    Currency,
    CurrencyResponse,
} from "src/app/services/response-models/currency.model";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class CurrenciesService {
    private readonly currenciesUrl = `${environment.apiBaseUrl}currencies`;

    constructor(
        private http: HttpClient,
        private store: Store
    ) {}

    public async getCurrencies(): Promise<Currency[]> {
        const { data } = await lastValueFrom(this.http.get<CurrenciesResponse>(this.currenciesUrl));
        this.store.dispatch(new SetCurrencies(data));

        return data;
    }

    getCurrencyById(currencyId: string): Promise<Currency> {
        return lastValueFrom(
            this.http
                .get<CurrencyResponse>(`${this.currenciesUrl}/${currencyId}`)
                .pipe(map((resp) => resp.data))
        );
    }
}
